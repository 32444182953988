.company-form-input-layout {
  .company-form-label {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #41b4d2;
    line-height: 20px;
  }
}

.request-company-divider {
  .ant-divider-horizontal {
    background-color: rgba(0, 0, 0, 0.09);
  }
}

.company-divider-title {
  color: #575665;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-row {
  margin-top: 0.5em;

  .text-col {
    font-size: 12px;
  }
  .request-company-button {
    margin-top: 1.5em;
  }
}
