.repository-download-multiple-modal-total-progress {
  text-align: center;
  font-weight: bold;
}

.repository-download-multiple-modal-total-progress-column {
  display: flex;
  justify-content: center;
}

.repository-download-multiple-modal-total-step-column {
  align-content: center;
}

.repository-download-multiple-modal-total-step-space {
  display: flex;
}
