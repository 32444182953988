.LoginForm {
  background-color: transparent;

  .login-form-line-horizontal-red {
    position: absolute;
    width: 10%;
    border: none;
    height: 5px;
    background: #41b4d2;
  }

  .login-form-enter-above-text {
    margin-top: 10px;
    font-weight: normal;
    font-size: 30px;
  }

  .login-form-input-layout {
    margin-left: 20px;
    margin-bottom: 0px;

    .login-form-label {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      color: #41b4d2;
    }

    .login-form-input {
      background: #ffffff;
      border: 1px solid #555463;
      box-sizing: border-box;
      font-size: 14px;
      border-radius: 2px;
    }
  }

  .login-form-button-center-enter {
    display: flex;
    justify-content: center;
    .login-form-button-layout {
      color: #41b4d2;
      height: 50%;
      width: 40%;
      font-size: 12px;
      background-color: white;
      border: 1px solid #41b4d2;
      box-sizing: border-box;
      border-radius: 25px;
    }
  }

  .login-form-forgotten-password {
    display: flex;
    justify-content: flex-end;
    margin-top: -1em;

    .login-form-link {
      padding: 0;
      font-weight: normal;
      font-size: 12px;
      color: rgba(55, 55, 55, 0.85);
    }
  }

  .request-access-link {
    margin-bottom: 1em;
  }
}
