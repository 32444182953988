.MailingList {
  .buttons-filters {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    margin-top: 2em;
    width: 100%;

    .mailing-list-button-export-all-users {
      box-sizing: border-box;
      border-radius: 20px;
    }
  }

  .filter-collapse-area {
    width: 100%;
    margin-bottom: 1em;
  }
}
