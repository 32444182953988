.NewTerms {
  margin-bottom: 4em;
  
  .version-label {
    color: #68cade;
  }

  .version-input {
    margin-top: 0.5rem;
  }

  .buttons-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cancel-button {
      margin-right: 1rem;
      cursor: pointer;
    }
  }
}
