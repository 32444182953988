.PagingTableWithSearch {
  .table-label-and-search-row {
    .table-entity-label {
      font-weight: bold;
    }
    .table-search-input-container {
      display: flex;
      justify-content: flex-end;
    }
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-table-thead > tr > th {
    background: #504f5f;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
  }

  .ant-table-row {
    cursor: pointer;

    .allowed-view-edit-icon:hover {
      path {
        fill: #0052b4;
      }
    }

    .unallowed-view-edit-icon:hover {
      path {
        fill: rgba(0, 82, 180, 0.3);
      }
    }
    .allowed-view-edit-blue-icon:hover {
      path {
        fill: #0781a0;
      }
    }

    .unallowed-view-edit-blue-icon:hover {
      path {
        fill: rgba(6, 116, 136, 0.4);
      }
    }
  }

  .ant-table-tbody {
    .selected-row {
      background: rgba(0, 0, 0, 0.2);

      &:hover {
        td {
          background: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  .ant-table-thead > tr > th:hover {
    background: #383841;
  }

  .ant-table-thead > tr > th.optionsColumn {
    width: 4em;
  }

  .ant-table-thead > tr > th.optionsColumn:hover {
    background: #504f5f;
  }

  .ant-table-thead {
    .address-column {
      width: 40em;
    }
  }

  .disable-loading-icon {
    .ant-spin-dot,
    .ant-spin-dot-spin {
      display: none;
    }
  }
}

.permissions-name {
  width: 55%;
}

.permissions-access {
  width: 22%;
}

.tableWithFirstRow tr:nth-of-type(1) {
  color: rgb(65, 180, 210);
  font-weight: bold;
}

.permissions-icon-table-column {
  .permissions-icon-row {
    .permissions-icon svg {
      width: 16px;
      height: 16px;
      margin-right: 0.6em;
      margin-top: -0.3em;
    }

    .permissions-icon :hover {
      fill: rgb(65, 180, 210);
    }
  }
}
