.NewsListing {
  .buttons-and-filter-button {
    margin-bottom: 1em;
    margin-top: 2em;
  }
  .news_listing-container {
    width: 100%;
    height: 100px;
    object-fit: 'cover';
    box-shadow: 0 2px 3px rgba(201, 201, 201, 0.5);
    cursor: pointer;

    .news_listing-card-img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .news_listing-img {
      object-fit: cover;
      height: 100px;
      width: 100%;
    }

    .news_listing-data-container {
      width: 60%;
      position: absolute;
      margin-left: 1rem;
      border-radius: 10px;
      top: 15%;

      .news_listing-title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
      }

      .news_listing-subtitle {
        font-style: normal;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
      }
    }
  }
  .news_listing-blank {
    width: 100%;
    height: 100px;
    border-style: dotted;
    border-width: 2px;
    border-color: rgba(128, 128, 128, 0.3);
    border-radius: 3px;

    .news_listing-data-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .news_listing-blank-title {
        color: rgba(128, 128, 128, 0.3);
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
      }
    }
  }

  .news_listing-news-text-label {
    margin: 10px;
    color: #41b4d2;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
  }
}