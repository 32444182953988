.LogsListing {
  .buttons-filters {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    margin-top: 2em;
    width: 100%;
  }

  .filter-collapse-area {
    width: 100%;
    margin-bottom: 1em;
  }
}
