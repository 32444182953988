.Navbar {
  display: flex;
  width: 100%;
  background-color: white;
  -webkit-box-shadow: 0px 1px 1px lightgrey;
  -moz-box-shadow: 0px 1px 1px lightgrey;
  box-shadow: 0px 1px 1px lightgrey;

  .ant-menu {
    .menu-dropdown-collapsed-root-icon {
      div.ant-menu-submenu-title {
        span {
          margin: 0;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }

    .i18n-dropdown {
      .ant-menu-submenu-title {
        display: flex;
        align-items: center;

        svg:nth-child(1) {
          margin-right: 1em;
        }
      }
    }
  }

  .home-logo {
    display: flex;
    width: 50%;
    align-items: center;
    padding-bottom: 0.1rem;

    .home-logo-clickable-area {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg:nth-child(2) {
        margin: 0 0.6em;
      }

      .dev-portal-label {
        font-size: 26px;
      }
    }
  }
}

#language-dropdown-pt-option,
#language-dropdown-en-option,
#language-dropdown-es-option {
  span {
    display: flex;
    align-items: center;

    svg:nth-child(1) {
      margin-right: 5em;
    }
  }
}

.ant-menu-submenu {
  .avatar-dropdown {
    .ant-menu-submenu-title {
      span:nth-child(1) {
        margin-right: 1em;
      }
    }
  }

  .ant-menu-vertical {
    padding-right: 1em;
  }

  .i18n-dropdown {
    .ant-menu-submenu-title {
      display: flex;
      align-items: center;

      svg:nth-child(1) {
        margin-right: 1em;
      }
    }
  }

  .special-menu-item-style {
    margin: 0 0 0 0.5em;
    height: 5px;
    cursor: default;
  }

  .divider-style {
    margin: 0;
  }
}
