.PagesCreated {
  .row-with-filter-and-button-to-create-new-page {
    margin-top: 4em;
    margin-bottom: 1em;
  }

  .filters-button-and-collapse {
    display: flex;
    justify-content: flex-end;

    span {
      color: black;
      font-weight: bold;
    }
  }

  .filter-collapse-area {
    width: 100%;
  }
}
