.Footer {
  background-color: #575665;
  height: 150px;
  color: white;
  font-size: 12px;
  margin-top: 1rem;

  .footer-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-buttons {
    cursor: pointer;
  }

  .footer-divider {
    height: 60%;
    border: 1px solid rgba(255, 255, 255, 0.38);
  }

  .footer-info {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 12px;
    padding-bottom: 1em;
    padding-right: 7em;

    p {
      text-align: right;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: disc !important;
    }
  }
}
