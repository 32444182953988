.overflow-row {
  margin-top: 0.1em;
  overflow-y: auto;

  .content-row {
    background: linear-gradient(
      180deg,
      rgba(236, 236, 236, 0.7) 0%,
      rgba(254, 254, 254, 0.7) 10%
    );
    // The min of the container height is 100% of the viewport height, minus
    // 64px from the navbar, 0.1em from the top margin of the container
    // 1rem from the top margin of the footer and 150px of the footer height
    min-height: calc(100vh - 64px - 0.1em - 1rem - 150px);
  }
}
