 .PagingTableWithExpansion {
  .ant-pagination-options {
    display: none;
  }

  .ant-table-thead > tr > th {
    background: #504f5f;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .ant-table-thead .resizeHandle {
    width: 10px;
    height: 100%;
    bottom: 0;
    cursor: col-resize;
    z-index: 5;
    background-color: #504f5f;
    padding: 0;
  }

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-thead > tr > th:hover {
    background: #383841;
  }

  .ant-table-thead > tr > th.optionsColumn {
    width: 4em;
  }

  .ant-table-thead > tr > th.optionsColumn:hover {
    background: #504f5f;
  }

  .ant-table-footer {
    background: none;

    .expansion-footer-row {
      display: flex;
      justify-content: center;

      .expansion-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        color: #41b4d2;

        svg:nth-child(1) {
          margin-left: 1rem;
        }
      }
    }
  }

  .ant-table-thead {
    .request-type-column,
    .request-actions-column {
      width: 10rem;
    }
  }
}
