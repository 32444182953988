.TagPanel {
  .ant-tabs-nav-list {
    .ant-tabs-tab:nth-child(4) {
      div {
        display: flex;
      }
    }
  }
  .new-tag-button {
    box-sizing: border-box;
    border-radius: 20px;
  }
}

.required-tag-field {
  color: red;
}

.modal-bold-text {
  font-weight: bold;
}