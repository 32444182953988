.PermissionsListing {
  .label-and-buttons {
    align-items: center;
    display: flex;
    margin-bottom: 1em;
    margin-top: 2em;
    width: 100%;

    .repositories-and-companies-label-container {
      .repositories-and-companies-label {
        font-weight: 700;
        margin: 0;
        line-height: 2rem;
      }
    }

    .change-permissions-tables-button-container {
      display: flex;
      justify-content: flex-end;

      .change-permissions-tables-button {
        display: flex;
        text-transform: uppercase;

        svg {
          fill: rgba($color: #000000, $alpha: 0.65);
          margin-right: 0.425rem;
          transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        &:hover {
          svg {
            fill: #68cade;
          }
        }
      }
    }
  }

  .permissions-listing-divider {
    border-color: #dedede;
    margin: 1.25rem 0;
  }

  .permissions-tables-container {
    width: 100%;
  }

  .permissions-tables-container > div {
    &:first-child {
      padding-right: 0.75rem;
    }
    &:last-child {
      padding-left: 0.75rem;
    }
  }
}
