.HomePage {
  margin: 0;
  max-height: 570px;

  .home-page-label-our-services {
    margin-top: 1rem;
    margin-bottom: 1.3rem;
    font-weight: bold;
    font-size: 24px;
  }

  .home-page-card-carousel {
    height: 300px;
    background-image: linear-gradient(-90deg, #ff000000, lightGray);
  }

  .home-page-card-solutions {
    margin: 0 20px;
  }
}
