.spin-class {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
}

.ant-spin-dot-spin {
    transform: rotate(
45deg
);
    animation: antRotate 1.2s infinite ease-in-out;
}