.CompanyForm {
  background-color: transparent;

  .text-row {
    margin-bottom: 4em;
    color: #575665;
    font-weight: bold;
  }

  .company-form-line-horizontal-red {
    position: absolute;
    width: 10%;
    border: none;
    height: 5px;
    background: #41b4d2;
  }
  .company-form-enter-above-text {
    margin-top: 20px;
    font-weight: normal;
    font-size: 30px;
  }
  .company-form-input-layout {
    .company-form-label {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      color: #41b4d2;
      line-height: 20px;
    }

    .company-form-input {
      background: #ffffff;
      border: 1px solid #555463;
      box-sizing: border-box;
      font-size: 12px;
      border-radius: 2px;
    }

    .company-form-search-button {
      margin-left: auto;
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .company-search-failed {
    background-color: #fafafa;
    margin: 2rem 0;

    .ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
