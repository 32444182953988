.Filter {
  background: rgba(196, 196, 196, 0.18);
  border-radius: 2px;
  margin-top: 20px;
  margin-bottom: 20px;

  .ant-collapse-item {
    .ant-collapse-header {
      display: none;
    }
  }  

  .ant-collapse-content-box {
    background: rgba(196, 196, 196, 0.18);
  }

  .filter-row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1em;
  }

  .filter-input {
    margin: 10px;
  }

  .filter-button {
    display: flex;
    justify-content: flex-end;
  }

  .filter-text-label {
    color: #41b4d2;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    margin-top: 2em;
  }

  .filter-button-filter {
    border: 1px solid #41b4d2;
    box-sizing: border-box;
    border-radius: 20px;
    margin-left: 20px;
  }

  .filter-button-clean {
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 20px;
    margin-right: 20px;
  }
}

.pt-datepicker {
  .ant-picker-panel-container {
    .ant-picker-panel {
      .ant-picker-month-panel {
        .ant-picker-body {
          .ant-picker-content {
            tbody {
              tr:nth-child(1) {
                td:nth-child(2) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Fev';
                    transform: translate(-50%, -50%);
                  }
                }
              }

              tr:nth-child(2) {
                td:nth-child(1) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Abr';
                    transform: translate(-50%, -50%);
                  }
                }

                td:nth-child(2) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Mai';
                    transform: translate(-50%, -50%);
                  }
                }
              }

              tr:nth-child(3) {
                td:nth-child(2) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Ago';
                    transform: translate(-50%, -50%);
                  }
                }

                td:nth-child(3) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Set';
                    transform: translate(-50%, -50%);
                  }
                }
              }

              tr:nth-child(4) {
                td:nth-child(1) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Out';
                    transform: translate(-50%, -50%);
                  }
                }

                td:nth-child(3) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Dez';
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }
          }
        }
      }

      .ant-picker-date-panel {
        .ant-picker-body {
          .ant-picker-content {
            thead {
              tr {
                th:nth-child(1) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(1):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Dom';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(2) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(2):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Seg';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(3) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(3):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Ter';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(4) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(4):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Qua';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(5) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(5):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Qui';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(6) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(6):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Sex';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(7) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(7):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Sab';
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }
    }
  }
}

.es-datepicker {
  .ant-picker-panel-container {
    .ant-picker-panel {
      .ant-picker-month-panel {
        .ant-picker-body {
          .ant-picker-content {
            tbody {
              tr:nth-child(1) {
                td:nth-child(1) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Ene';
                    transform: translate(-50%, -50%);
                  }
                }
              }

              tr:nth-child(2) {
                td:nth-child(1) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Abr';
                    transform: translate(-50%, -50%);
                  }
                }

                td:nth-child(2) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Mayo';
                    transform: translate(-50%, -50%);
                  }
                }
              }

              tr:nth-child(3) {
                td:nth-child(2) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Ago';
                    transform: translate(-50%, -50%);
                  }
                }

                td:nth-child(3) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Sept';
                    transform: translate(-50%, -50%);
                  }
                }
              }

              tr:nth-child(4) {
                td:nth-child(3) {
                  div {
                    visibility: hidden;
                    position: relative;
                  }

                  div:after {
                    visibility: visible;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    content: 'Dic';
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }
          }
        }
      }

      .ant-picker-date-panel {
        .ant-picker-body {
          .ant-picker-content {
            thead {
              tr {
                th:nth-child(1) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(1):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Dom';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(2) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(2):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Lun';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(3) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(3):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Mar';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(4) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(4):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Miér';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(5) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(5):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Jue';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(6) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(6):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Vier';
                  transform: translate(-50%, -50%);
                }

                th:nth-child(7) {
                  visibility: hidden;
                  position: relative;
                }

                th:nth-child(7):after {
                  visibility: visible;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  content: 'Sáb';
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }
    }
  }
}
