.NewRegisterNews {
  .new_register-news-container {
    width: 100%;
    height: auto;
    object-fit: 'cover';

    .new-register-card-img {
      object-fit: cover;
      height: 100%; 
      width: 100%;
    }

    .new-register-news-img {
      object-fit: cover;
      height: 100px;
      width: 100%;
      cursor: pointer;
    }

    .new-register-news-data-container {
      width: 65%;
      position: absolute;
      margin-left: 4rem;
      border-radius: 10px;
      top: 20%;

      .new-register-news-blue-horizontal-line {
        margin: 10px 10px;
        width: 75px;
        height: 6px;
        font-weight: bold;
        background: #41b4d2;
      }

      .new-register-news-title {
        font-style: normal;
        font-weight: bold;
        line-height: 36px;
        font-size: 32px;
      }

      .new-register-news-subtitle {
        font-style: normal;
        line-height: 36px;
        font-size: 16px;
      }
    }
  }

  .new-register-news-text-label label {
    margin: 10px;
    color: #41b4d2;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
  }

  .new-register-news-button-save {
    border: 1px solid #41b4d2;
    color: #41b4d2;
    box-sizing: border-box;
    border-radius: 20px;
  }

  .new-register-news-button-cancel {
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 20px;
    margin-right: 20px;
    color: #373737;
  }

  .new-register-news-banner-selector-style {
    object-fit: 'cover';
    height: '100px';
    width: '100%';
    cursor: 'pointer';
  }
}
