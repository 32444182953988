.EvaluationTable {
  .ant-pagination-options {
    display: none;
  }

  .ant-table-thead > tr > th {
    background: #504f5f;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .ant-table-thead .resizeHandle {
      width: 2px;
      height: 100%;
      bottom: 0;
      cursor: col-resize;
      z-index: 5;
      background-color: #504f5f;
      padding: 0;
  }

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-row-disabled-cursor {
    .ant-table-row {
      cursor: initial;
    }
  }

  .ant-table-thead > tr > th:hover {
    background: #383841;
  }

  .ant-table-thead > tr > th.optionsColumn {
    width: 4em;
  }

  .ant-table-thead > tr > th.optionsColumn:hover {
    background: #504f5f;
  }

  .ant-table-thead {
    .address-column {
      width: 40em;
    }
  }

  .paging-table-options-column {
    display: flex;
    // align-items: center;
    background-color: inherit;
    width: 100%;
    justify-content: flex-end;
    border: none;
    transition: none;
    
  
    .ant-menu-item {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
    }
    
    .ant-menu-item-disabled svg path {
      fill: rgba($color: #000000, $alpha: 0.2)
    }
    
    svg {
      width: 16px;
      height: 16px;
      margin-top: -0.3em; 
    }
    
    .ant-menu-item:active, .ant-menu-item-selected {
      background-color: inherit;
      transition: none;
    }
  }

}

.hide-checkbox {
  display: none;
}

.repository-list-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 2rem;

  svg {
    width: 16px;
    height: 16px;
    margin-top: -0.3em; 
  }
}