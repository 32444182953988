.notifications-no-data, .notifications-list {
  min-width: 300px;
}

.notifications-no-data {
	margin-top: 14px;
  margin-bottom: 14px;
}

.notifications-delete-all-button {
  display: flex;
  justify-content: center;
}

.notifications-list {
  max-height: 390px;
  width: 450px;
}

.notifications-list-item {
  width: 100%;
  background-color: white;
  padding: 4px 0 4px 0;
}

.notifications-list-item:hover {
  width: 100%;
  border-radius: 4px;
  background-color: rgba(171, 197, 255, 0.5);
  transition: all 0.3s;
}

.notifications-list-item-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.notifications-list-item-content:hover {
  cursor: pointer;
}

.notifications-list-item-icon, .notifications-list-item-icon-close {
  display: flex;
  justify-content: center;
}

.notifications-list-item-icon {
  font-size: 20px;
}

.notifications-list-item-icon:hover {
  cursor: pointer;
}

.notifications-list-item-icon-close {
  color: gray;
  transition: all 0.3s;
}

.notifications-list-item-icon-close:hover {
  color: black;
  cursor: pointer;
}
