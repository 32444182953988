.search-button {
  border-radius: 50px;
  border: 1px solid rgba(74, 79, 85, 0.65);
  display: flex;
  align-items: center;
  overflow: hidden; 

  .ant-input-wrapper {
    flex-grow: 1; 

    input {
      width: 0em;
      border: none;
      -webkit-transition: width 0.4s ease-in-out;
      transition: width 0.4s ease-in-out;
    }

    input:focus {
      width: 25em;
      box-shadow: none;
    }

    &:hover input {
      width: 25em;
    }
  }

  .ant-input-group-addon {
    border-left: none; 
    align-items: center;
    background: transparent; 
  }

  .ant-input-search-button {
    border: none; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border-color: #68cade;
  }

  &:focus-within {
    border-color: #68cade;
    box-shadow: 0 0 0 2px rgba(65, 180, 210, 0.2);
  }
}
