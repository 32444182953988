@import '~antd/dist/antd.less';

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url('./assets/fonts/inter/Inter-Thin-slnt=0.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url('./assets/fonts/inter/Inter-ExtraLight-slnt=0.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('./assets/fonts/inter/Inter-Light-slnt=0.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('./assets/fonts/inter/Inter-Regular-slnt=0.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('./assets/fonts/inter/Inter-Medium-slnt=0.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('./assets/fonts/inter/Inter-SemiBold-slnt=0.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('./assets/fonts/inter/Inter-Bold-slnt=0.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url('./assets/fonts/inter/Inter-ExtraBold-slnt=0.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: url('./assets/fonts/inter/Inter-Black-slnt=0.ttf');
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.px1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.group {
  display: flex;
  justify-content: space-between;
}

#root {
  height: 100vh;
}

@primary-color: #41B4D2;