.RequestAccessForm {
  display: flex;
  justify-content: center;
  margin-right: 5%;

  .request-access-upload-image {
    display: flex;
    justify-content: left;
  }

  Input {
    font-size: 14px;
  }

  .request-access-form-label {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #41b4d2;
  }
  .request-access-form-data {
    width: 70%;

    .password-confirmation-message,
    .email-confirmation-message {
      font-size: 14px;
      color: #ff4d4f;
    }
  }

  .observation-text-row {
    display: flex;
    align-items: center;
    margin-top: 4em;

    .observation-text {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #575665;
      font-weight: bold;

      .observation-icon {
        margin-right: 4px;
      }
    }
  }
}
