.Steps {
  margin-top: 50px;

  .steps-content {
    margin-top: 16px;
    border-radius: 2px;
    min-height: 200px;
    text-align: left;
    padding-top: 50px;
  }

  .steps-align {
    justify-content: center;
  }

  .steps-action {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    justify-content: flex-end;
  }

  .steps-previous-button {
    color: #373737;
  }
}
