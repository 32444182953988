.progress-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pre-wrap {
    white-space: pre-wrap;
    word-wrap: break-word;
}
