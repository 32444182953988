.return-symbol {
  margin-right: 1em;
}

#return-button-row {
  margin: -1rem 0;
}

.return-button {
  display: flex;
  float: right;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2em;
  font-size: 1rem;
  color: #41b4d2;
  cursor: pointer;
}