.LoginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
}

.Login {
  display: flex;
  align-items: center;
  justify-content: center;

  .header-columns {
    width: 100%;
    display: flex;
    justify-content: center;

    .login-column-header {
      display: 'flex';
      flex-direction: 'column';
      align-items: 'flex-start';

      .login-column-header-divider {
        height: 6px;
        width: 75px;
        background: #41b4d2;
        border-style: none;
        margin: 0;
        margin-bottom: 1em;
      }

      .login-column-header-title {
        font-size: 32px;
        line-height: 45px;
      }
    }
  }

  .login-request-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1em;

    .request-text-button-col {
      .login-request-text {
        display: flex;
        justify-content: center;
        font-size: 20px;
        width: 50%;
        text-align: center;
      }

      .login-request-button {
        justify-content: center;
        display: flex;
        width: 100%;
        .request-button {
          justify-content: center;
          margin: 20px 0 20px 0;
          padding: 0 5em 0 5em;
          align-items: center;
          cursor: pointer;
          font-weight: bold;
          font-size: 12px;
          color: #fff;
          background-color: #41b4d2;
        }
      }
    }
  }

  .login-divider-column {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1em;

    .ant-divider-vertical {
      height: 18em;
      background-color: rgba(0, 0, 0, 0.09);
    }
  }
  .IngenicoLogo {
    width: 100%;
    justify-content: flex-end;
  }
}
