.CardPasswordValidation {
  .card-validate-password {
    color: #41b4d2;

    h4 {
      margin-bottom: 2em;
    }

    .valid {
      color: green;
    }

    .invalid {
      color: red;
    }
  }
}
