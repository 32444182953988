.Terms {
  .terms-title-row {
    display: flex;
    justify-content: center;

    .terms-title {
      font-size: 24px;
      color: #41b4d2;
    }
  }

  .term-paragraph-index {
    font-weight: bold;
  }
}
