.Header {
  .left-column {
    display: flex;
    flex-direction: column;
    margin-top: 3em;
  }

  .left-column-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .left-column-header-divider {
      height: 6px;
      width: 75px;
      background: #41b4d2;
      border-style: none;
      margin: 0;
      margin-bottom: 1em;
    }

    .left-column-header-title {
      font-size: 32px;
      line-height: 45px;
      font-weight: bold;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .right-column {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 0 0 32%;
    max-width: 33.33333333%;
    margin: 0.5em;

    .red-ellipsis {
      margin-right: 1.5em;
    }
  }
}
