.request-modal {
  color: #41b4d2;
  font-weight: bolder;
  width: 200em;

  .description {
    color: goldenrod;
    font-weight: normal;
    padding: 0.5em 0.5em 0.5em 0.5em;
    border-style: solid;
    border-color: #faad14;
    border-width: 0.5px;
    background-color: rgb(250, 246, 229);

    .description-title svg {
      margin-right: 0.5em;
    }
    .description-content {
      display: flex;
      justify-content: center;
    }
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  .request-form {
    padding: 2px;
    margin-top: 1.5em;
    justify-content: space-around;
  }

  .request-form-item label {
    color: #41b4d2;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
  }

  .ant-form-item-control-input {
    min-height: 0;
  }

  .request-form-item-input {
    color: #373737;
    font-style: normal;
    font-size: 12px;
    margin-top: -1.2em;
    word-wrap: break-word;
    padding-top: 0.8em;
  }

  .form-approve-buttons {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 1em;
  }

  .form-cancel-button {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .register-form-button {
    font-weight: bold;
    box-sizing: border-box;
    border-radius: 20px;
    margin-bottom: -2.3em;
    width: 100%;
  }
  .register-button {
    font-weight: bold;
    box-sizing: border-box;
    border-radius: 20px;
    margin-bottom: -2.3em;
  }

  .cancel-button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 20px;
    color: #373737;
    margin-bottom: -3em;
  }
}
