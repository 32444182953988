.UsersListing {
  .buttons-filters {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    margin-top: 2em;
    width: 100%;

    .users-listing-button-new-user {
      box-sizing: border-box;
      border-radius: 20px;
    }
  }

  .filter-collapse-area {
    width: 100%;
    margin-bottom: 1em;
  }
}

.ResetPasswordModalContent {
  margin-top: 2em;
  width: 100%;

  .reset-password-redirect-icon {
    align-items: center;
    cursor: pointer;
    margin-left: 1em;
  }

  .reset-password-link-input {
    width: 90%;
  }

  .reset-password-paragraph {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 1em;
  }

  .reset-password-link-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -0.5em;
  }
}

.reset-password-modal {
  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
          .ant-modal-confirm-content {
            margin-left: 0;
          }
        }
      }
    }
  }
}
