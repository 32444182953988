.tagColorDot {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
}

.language-tag-close-button {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}

.language-tag-close-button:hover {
  color: rgba(0, 0, 0, 0.85);
}

.CreatePages {
  .editor-content-row-style {
    margin-top: -3em;
  }

  .header-title {
    margin-bottom: 0;
  }

  .tag-row-style {
    margin-bottom: 2em;
  }

  .return-symbol {
    margin-right: 1em;
  }

  #return-button-row {
    margin-bottom: 1rem;
  }

  .return-button {
    display: flex;
    float: right;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2em;
    color: #41b4d2;
    cursor: pointer;
  }

  .dropdown-language {
    display: flex;
    align-items: center;
    padding: 0;
  }

  .select-language-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    line-height: 29px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 1em;
  }

  .language-row-style {
    display: flex;
    justify-content: flex-start;
  }

  .vertical-red-bar {
    padding: 0;
  }

  .button-row-style {
    display: flex;
    justify-content: flex-start;
    margin-top: 1em;
  }

  .publish-edit-buttons-row-style {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
  }

  #publish-button {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    font-size: 14px;
    height: 32px;
  }

  #add-language-button {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    font-size: 14px;
    height: 32px;
  }

  #cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    width: 100%;
    color: #003399;
    border-style: none;
    margin-top: -3em;
  }

  #edit-button {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    font-size: 14px;
    height: 32px;
  }

  .edit-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .button-style {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .tag-plus {
    background: #fff;
    border-style: dashed;
    cursor: pointer;
  }

  .tree-view-button {
    border-style: none;
    font-size: 10px;
    font-weight: bold;
    box-shadow: none;
  }

  .input-form-editor {
    margin-bottom: 2em;

    .title-input {
      width: 100%;
      border: 1px solid #e1e1e1;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      color: #000000;
      height: 40px;
      margin: 0;
    }
    .title-input-read-only {
      width: 100%;
      border-style: none;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      color: #000000;
      height: 40px;
      margin: 0;
    }

    .description-input {
      width: 100%;
      border: 1px solid #e1e1e1;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #000000;
      height: 58px;
      margin: 0;
      margin-top: -5em;
    }
    .description-input-read-only {
      width: 100%;
      border-style: none;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #000000;
      height: 58px;
      margin: 0;
      margin-top: -5em;
    }
  }
}
