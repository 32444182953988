.table-operations-buttons-container {
  display: flex;
  gap: 1rem;

  .table-operations-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
}

.table-footer-button-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .table-footer-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-weight: bolder;
    color: #41B4D2;
  }
}

.ant-table-thead > tr > th {
  background: #504f5f;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  border-right: none; 
}

.ant-table-thead > tr > th:first-child {
  border-left: none; 
}
