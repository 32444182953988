.LanguageSelection {
  margin-top: 2rem;

  .languages-area {
    display: flex;
    flex-direction: column;

    svg:nth-child(1) {
      height: 2rem;
    }

    .language-option {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      height: 2rem;
      margin: 0.5rem 0;

      .ant-col:nth-child(1) {
        height: 100%;
      }
    }
  }
}
