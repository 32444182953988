.progress-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-container {
    height: 100%;
}
