.About-page {
  text-align: justify;

  .about-page-h1-style {
    display: flex;
    justify-content: left;
    color: #41b4d2;
    font-weight: bold;
    text-shadow: 2px 2px 4px #2125293d;
  }

  .about-page-image-col-style {
    align-items: center;
    display: flex;
    justify-content: center;

    .about-page-left-image-style {
      max-inline-size: 70%;
      box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2),
        0 6px 20px 10px rgba(0, 0, 0, 0.19);
    }

    .about-page-right-image-style {
      max-inline-size: 60%;
      box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2),
        0 6px 20px 10px rgba(0, 0, 0, 0.19);
    }
  }

  .about-page-divider-style {
    margin-top: 4em;
    margin-bottom: 3em;
  }

  .about-page-click-here-style {
    color: #41b4d2;
    cursor: pointer;
  }

  .left-column-header {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'flex-start';
    margin-top: 2em;

    .left-column-header-divider {
      height: 6px;
      width: 75px;
      background: #41b4d2;
      border-style: none;
      margin: 0;
      margin-bottom: 1em;
    }

    .left-column-header-title {
      font-size: 32px;
      line-height: 45px;
    }
  }
}
