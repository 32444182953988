.profile-form-row{
    margin-top: 6rem;

    .my-profile-form label{
        color: #41b4d2;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
    }

    .disable-input:disabled{
        color: rgba(0, 0, 0, 0.65);
        padding-left: 0;
    }

    .profile-password-form{
        margin-top: 3rem;
    }
    
    .my-profile-change-password-form label{
        color: #41b4d2;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
    }

}