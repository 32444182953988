.IngenicoWorld {
  height: 100%;

  .left-column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .left-column-header {
      display: 'flex';
      flex-direction: 'column';
      align-items: 'flex-start';

      .left-column-header-divider {
        height: 6px;
        width: 75px;
        background: #41b4d2;
        border-style: none;
        margin: 0;
        margin-bottom: 1em;
      }

      .left-column-header-title {
        font-size: 32px;
        line-height: 45px;
      }
    }

    .thanks-area {
      margin-top: 8em;

      .thanks-area-label {
        font-size: 20px;
        font-weight: bold;
        line-height: 33px;
        margin: 0;
        color: #41b4d2;
      }
    }
  }

  .ingenico-world {
    display: flex;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
