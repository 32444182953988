.repository-buttons-col {
  margin: 2em 0;
  display: flex;
  justify-content: space-between;
}

.repository-breadcrumb-col {
  margin: 0 0 1em 0;
}

.move-selected-button, .download-selected-button,
.copy-selected-button, .delete-selected-button {
  border-style: none;
  font-size: 12px;
  font-weight: bold;
  color: rgba(55, 55, 55, 0.56);
  box-shadow: none;
}

.repository-description-title {
  font-weight: bold;
}

.repository-header-documentation {
  margin-top: 2rem;
  border-left: 1px solid rgba(55, 55, 55, 0.322);
  padding: 1rem;
  min-width: 25vw;
}


.repository-list {
  .repository-list-header {
    display: flex;
    /* Calculate the width by subtracting the width of .actions-buttons and half the width of .repository-last-modified-header from 100% */
    width: calc(100% - 128px - 48px);
    justify-content: space-between;
    align-items: center;

    .header-repository-text {
      font-weight: bold;
    }
  }
  .repository-last-modified, .repository-last-modified-header {
    width: 150px;
    text-align: center;
  }

  .repository-list-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;

  }
  .repository-list-item:hover {
    background-color: rgba(236, 236, 236, 0.274);
  }
  
  .actions-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    width: 120px;

    .item-button {
      color: #003399;
      border: none;
      background-color: transparent;
    }
    .item-button:hover {
      color: #41B4D2;
    }
  }
}

.modal-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .required-input {
    color: red;
  }
}

.repository-details-title {
  font-weight: bold;
}

.repository-breadcrumb-link {
  transition: color .3s;
  font-size: 16px;
  color: #41B4D2;
}

.repository-breadcrumb-link:hover {
  cursor: pointer;
  color: #2392ad;
  text-decoration: underline;
  transition: color .3s;
}

.repository-breadcrumb-link-current {
  font-size: 16px;
  color: #2392ad;
}

.left-column-header-title {
  margin-bottom: 0;
}

.evaluation-button {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.associated-documents-scrollable {
  max-height: 200px;
  overflow-y: auto;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
}
.repository-buttons-col {
  margin: 2em 0;
  display: flex;
  justify-content: space-between;
}

.repository-breadcrumb-col {
  margin: 0 0 1em 0;
}

.move-selected-button {
  border-style: none;
  font-size: 12px;
  font-weight: bold;
  color: rgba(55, 55, 55, 0.56);
  box-shadow: none;
}

.repository-description-title {
  font-weight: bold;
}

.repository-header-documentation {
  margin-top: 2rem;
  border-left: 1px solid rgba(55, 55, 55, 0.322);
  padding: 1rem;
}

.evaluation-button {
  margin-right: 16px;
}

.repository-list {
  .repository-list-header {
    display: flex;
    /* Calculate the width by subtracting the width of .actions-buttons and half the width of .repository-last-modified-header from 100% */
    width: calc(100% - 128px - 48px);
    justify-content: space-between;
    align-items: center;

    .header-repository-text {
      font-weight: bold;
    }
  }
  .repository-last-modified, .repository-last-modified-header {
    width: 150px;
    text-align: center;
  }

  .repository-list-item {
    cursor: pointer;
    display: flex;
    justify-content: space-between;

  }
  .repository-list-item:hover {
    background-color: rgba(236, 236, 236, 0.274);
  }
  
  .actions-buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    width: 120px;

    .item-button {
      color: #003399;
      border: none;
      background-color: transparent;
    }
    .item-button:hover {
      color: #41B4D2;
    }
  }
}
  
.modal-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .required-input {
    color: red;
  }
}

.repository-details-title {
  font-weight: bold;
}

.repository-breadcrumb-link {
  transition: color .3s;
}

.repository-breadcrumb-link:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
  transition: color .3s;
  }