.TermsListing {
  .buttons-filters {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    margin-top: 2em;
    width: 100%;

    .terms-listing-button-new-term {
      box-sizing: border-box;
      border-radius: 20px;
    }
  }
  .filter-collapse-area {
    width: 100%;
    margin-bottom: 1em;
  }
}
