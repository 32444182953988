.error-message-notification {
  background-color: #f65058 !important;
  cursor: pointer;
  color: ghostwhite;

  .ant-notification-notice-message {
    color: ghostwhite;
  }
  span {
    color: #e30613 !important;
  }
}

.success-message-notification {
  cursor: pointer;
  background-color: #baf5ac !important;
}

.warning-message-notification {
  cursor: pointer;
  background-color: #f9ff9e !important;
}
