.PopUpEditor {
  margin: auto;
  width: 100%;
  padding: 20px 32px 24px 32px;
  border-radius: 2px;
  box-shadow: 
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .pop-up-icons {
    margin-top: 10px;
  }

  .pop-up-title {
    margin-bottom: 15px;
  }
}
