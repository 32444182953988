.page-buttons-row {
  margin-top: 2em;

  .page-buttons-col {
    display: flex;
    justify-content: flex-end;
  }
}

.no-pages-existing {
  width: 100%;
}

.page-listing-title-search {
  margin-top: 2em;
  margin-bottom: 2em;

  .listing-title {
    display: flex;
    font-size: 20px;
    color: #000000;
  }

  .search-page-field {
    margin-right: 1.6em;
  }
}

.ant-card {
  height: 100%;
}

.pages-card-listing {
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px 40px;

  .pages-card-item {
    .ant-card-meta-description {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      direction: ltr;
      text-overflow: ellipsis;
    }
  }
}

.listing-result {
  display: flex;
  align-items: center;
  margin-top: 4em;

  .return-button {
    cursor: pointer;
  }

  .search-button-results {
    border-radius: 50px;
    border: 1px solid rgba(74, 79, 85, 0.65);
    display: flex;
    align-items: center;
    overflow: hidden; 

  .ant-input-wrapper {
    flex-grow: 1; 

    input {
      // width: 0em;
      border: none;
      -webkit-transition: width 0.4s ease-in-out;
      transition: width 0.4s ease-in-out;
    }

    input:focus {
      width: 100%;
      box-shadow: none;
    }

    &:hover input {
      width: 100%;
    }
  }

  .ant-input-group-addon {
    border-left: none; 
    align-items: center;
    background: transparent; 
  }

  .ant-input-search-button {
    border: none; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border-color: #68cade;
  }

  &:focus-within {
    border-color: #68cade;
    box-shadow: 0 0 0 2px rgba(65, 180, 210, 0.2);
  }
  }
}

.page-card-results {
  margin-top: 2em;
}

.ant-avatar.ant-avatar-icon {
  width: 51px;
  height: 51px;
}

.ant-card-head {
  border-bottom: none;
  line-height: 2px;
}

.ant-input-search-icon::before {
  border-left: none;
}

.classification-level-one-title {
  margin-top: 3rem;
  color: #D46B08;
}

.classification-level-two-title {
  margin-left: 1rem;
  color: #08979C;
}

.classification-level-three-title {
  margin-left: 2rem;
  color: #1D39C4;
}

.classification-item {
  display: flex;
  align-items: center;
  justify-content: center;

  .page-card-item {
    align-items: flex-start;
  }
}