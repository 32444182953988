.PopUp {
  padding-bottom: 30px;

  .buttons-area {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cancel-button {
      margin-right: 1rem;
      cursor: pointer;
    }
  }
}