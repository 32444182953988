.PageResult {
  width: 100%;
  margin: 10px 0;

  .result-avatar-icon {
    margin: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }

  .result-card {
    .result-title {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
    }

    .result-description {
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #373737;
    }

    .result-path {
      margin-top: 5px;
      margin-left: 17px;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: #373737;
    }
  }

  .result-horizontal-line {
    margin: 30px 10%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
  }
}