.DeleteSelectedButton {
  border-style: none;
  font-size: 10px;
  font-weight: bold;
  color: rgba(55, 55, 55, 0.56);
  box-shadow: none;

  .trash-icon-button {
    margin-left: 0.6em;
    margin-bottom: -0.3em;
    padding: 0.1em;
  }
}