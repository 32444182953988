.Carousel {
  width: 100%;
  height: 12rem;
  display: inline-block;

  .CarouselHeader {
    width: auto;
    height: 12rem;

    .carousel-header-items {
      cursor: pointer;
      object-fit: cover;
      height: 12rem;
    }

    .carousel-header-image {
      .image {
        object-fit: cover;
        width: 100%;
        height: 12rem;
      }
    }

    .carousel-information-labels {
      height: 12rem;
      position: absolute;
      margin-left: 5rem;
      border-radius: 10px;
      top: 10%;

      .carousel-information-labels-header-red-horizontal-line {
        margin: 10px 0;
        width: 75px;
        height: 6px;
        font-weight: bold;
        background: #41b4d2;
      }

      .carousel-information-label-text-title {
        font-style: normal;
        font-weight: bold;
        line-height: 36px;
        font-size: 32px;
      }

      .carousel-information-label-text-subtitle {
        font-style: normal;
        line-height: 36px;
        font-size: 16px;
      }
    }
  }

  .carousel-buttons {
    width: 100%;
    position: absolute;
    top: 9%;
    left: 0;

    .carousel-button-prev {
      float: left;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    .carousel-button-next {
      float: right;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    .carousel-button-icon {
      color: black;
      font-size: 30px;
    }
  }

  .ant-carousel .slick-dots li button {
    background: rgba(114, 114, 114, 0.9);
    height: 8px;
    border-radius: 10px;
    opacity: 0.4;
  }

  .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: #41b4d2;
  }
}

.EmptyCarousel {
  height: 4rem;
}
