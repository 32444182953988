.concluded-request-modal {
  color: #41b4d2;
  font-weight: bolder;

  .ant-modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  .request-form-item label {
    color: #41b4d2;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
  }

  .request-form-item-input {
    color: #373737;
    font-style: normal;
    font-size: 12px;
    margin-top: -1.2em;
    word-wrap: break-word;
  }

  .form-buttons {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    margin: 0.3em 2rem -2em 0;
  }

  .button-register {
    font-weight: bold;
    box-sizing: border-box;
    border-radius: 20px;
  }

  .button-cancel {
    border: none;
    font-weight: bold;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 20px;
    margin-right: 20px;
    color: #373737;
  }
}
