.ForgotPasswordForm {
  .left-column-content {
    font-size: 18px;
    line-height: 33px;
  }

  .left-column-form {
    width: 100%;

    .email-input-outline-label {
      font-weight: bold;
      font-size: 12px;
      color: #41b4d2;
      line-height: 18px;
    }

    .left-column-form-buttons {
      display: flex;
      justify-content: flex-end;

      .left-column-form-buttons-cancel {
        color: #373737;
      }
    }
  }
}
