.New-Register {
  border-radius: 2px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 60px;
  margin-bottom: 20px;
  justify-content: left;
  align-items: center;
  padding: 10px;

  .register-input {
    margin: 10px;

    .register-button {
      float: right;
      display: flex;
      align-content: center;
    }

    .button-register {
      border: 1px solid #41b4d2;
      color: #41b4d2;
      box-sizing: border-box;
      border-radius: 20px;
    }

    .button-cancel {
      border: none;
      background-color: transparent;
      box-sizing: border-box; 
      border-radius: 20px;
      margin-right: 20px;
      color: #373737;
    }
  }

  .center {
    border: 0.6px solid rgba(0, 0, 0, 0.2);
    transform: rotate(90deg);
  }

  .register-text-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .register-text-label label {
    color: #41b4d2;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
  }

  .alertMessage {
    display: flex;
    position: absolute;
    color: #41b4d2;
    margin-top: 0.3em;
    font-size: 12px;
  }

  .observation-text-row {
    display: flex;
    align-items: center;
    margin-top: 4em;

    .observation-text {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #575665;
      font-weight: bold;

      .observation-icon {
        margin-right: 4px;
      }
    }
  }
}
