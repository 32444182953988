.CreatePasswordForm {
  .create-password-form {
    width: 80%;
    .left-column {
      font-size: 18px;
      line-height: 33px;
      margin-top: -5px;
    }

    .left-column-form {
      width: 100%;

      .password-input-data {
        width: 100%;
      }

      .password-input-label label {
        font-weight: bold;
        font-size: 12px;
        color: #41b4d2;
        line-height: 18px;
      }

      .left-column-form-buttons {
        display: flex;
        justify-content: flex-end;

        .cancel-create-password-button {
          color: #373737;
        }
      }

      .password-observation-not-equal {
        color: #41b4d2;
        font-size: 12px;
        margin-top: 0.5em;
      }
    }
  }
}
