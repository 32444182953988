.Solution {
  width: 100%;

  .solution-size-image {
    width: 90%;
    height: 200px;
  }

  .products-and-services-title {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 21px;
    color: rgba(0, 0, 0, 0.85);
  }

  .digital-solutions-description {
    margin-bottom: 1rem;
  }

  .divider-column {
    display: flex;
    justify-content: center;

    .divider {
      height: 14rem;
    }
  }

  .payment-terminals-description {
    margin-bottom: 2.5rem;
  }

  .home-buttons {
    display: flex;
    justify-content: center;
  }
}
